var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"inspectorId"}},[_c('div',{staticClass:"page-container mb-2",attrs:{"id":"filter-custom"}},[_c('h3',{staticClass:"header"},[_vm._v(" Bộ lọc ")]),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Năm "),_c('span',{staticClass:"required"})]),_c('v-select',{attrs:{"reduce":function (label) { return label; },"options":_vm.dataFilterYear,"placeholder":'Năm'},on:{"input":_vm.filterYear},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Huyện"),_c('span',{staticClass:"required"})]),_c('v-select',{attrs:{"reduce":function (label) { return label.id; },"label":"name","options":_vm.dataFilteType,"placeholder":'Huyện'},on:{"input":_vm.filterDistrict},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}])})],1)],1)],1)],1),_c('div',{staticClass:"page-container-table"},[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm báo cáo',"hideExportFile":false,"hideImportFile":false,"hideDowload":false,"hideAdd":false,"hideDelete":false},on:{"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"sort-options":{
        enabled: false,
      },"select-options":{
        enabled: false,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',[_vm._v(" "+_vm._s(props.row.name)+" ")]):(props.column.field == 'action')?_c('span',[_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Xem báo cáo'),expression:"'Xem báo cáo'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"EyeIcon","size":"18"},on:{"click":function($event){return _vm.showPDF(props.row.id, props.row.name)}}})],1),_c('span',{staticClass:"win ml-2"},[_c('b-dropdown',{attrs:{"variant":"link","boundary":"viewport","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Tải xuống'),expression:"'Tải xuống'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"DownloadIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.downloadFile(props.row.id, 'Word', props.row.name)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"DownloadIcon","size":"16"}}),_c('span',[_vm._v("Xuất file Word")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.downloadFile(props.row.id, 'PDF', props.row.name)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"DownloadIcon","size":"16"}}),_c('span',[_vm._v("Xuất file PDF")])],1)],1)],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.pageChange}}),_c('modal-see-pdf',{attrs:{"id":_vm.modalSeePdf,"pdfsrc":_vm.pdfSrc}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }