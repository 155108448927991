<template>
  <div id="inspectorId">
    <div
      id="filter-custom"
      class="page-container mb-2"
    >
      <h3
        class="header"
      >
        Bộ lọc
      </h3>
      <b-row>
        <!-- Người lao động -->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Năm <span class="required"></span></label>
            <v-select
              :reduce="label => label"
              :options="dataFilterYear"
              :placeholder="'Năm'"
              @input="filterYear"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Huyện<span class="required"></span></label>
            <v-select
              :reduce="label => label.id"
              label="name"
              :options="dataFilteType"
              :placeholder="'Huyện'"
              @input="filterDistrict"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

      </b-row>
    </div>
    <div class="page-container-table">
      <!--phần header-->
      <button-all-header
        :contentBtnAdd="'Thêm báo cáo'"
        :hideExportFile="false"
        :hideImportFile="false"
        :hideDowload="false"
        :hideAdd="false"
        :hideDelete="false"
        @search="search($event)"
      />
      <!--Phần Bảng -->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: false,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Họ tên -->
          <span v-if="props.column.field == 'name'">
            {{ props.row.name }}
          </span>
          <!-- ngày sinh -->

          <!-- Chức năng -->
          <span v-else-if="props.column.field == 'action'">
            <span>
              <feather-icon
                v-b-tooltip.hover.top="'Xem báo cáo'"
                icon="EyeIcon"
                size="18"
                class="text-body"
                @click="showPDF(props.row.id, props.row.name)"
              />
            </span>
            <span class="win ml-2">
              <b-dropdown
                variant="link"
                boundary="viewport"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    v-b-tooltip.hover.top="'Tải xuống'"
                    icon="DownloadIcon"
                    size="16"
                    class="text-body"
                  />
                </template>
                <!-- xem chi tiet  -->
                <b-dropdown-item
                  @click="downloadFile(props.row.id, 'Word', props.row.name)"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                    class="mr-50"
                  />
                  <span>Xuất file Word</span>
                </b-dropdown-item>
                <!-- xem bao cao  -->
                <b-dropdown-item @click="downloadFile(props.row.id, 'PDF', props.row.name)">
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                    class="mr-50"
                  />
                  <span>Xuất file PDF</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>

          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />

      <!-- modal xem file pdf -->
      <modal-see-pdf
        :id="modalSeePdf"
        :pdfsrc="pdfSrc"
      />

    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ModalSeePdf from './components/ModalSeePDF.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BBadge,
    BDropdown,
    BDropdownItem,
    ShowFile,
    vSelect,
    ModalSeePdf,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      pdfSrc: '',
      totalRecord: 10,
      modalSeePdf: 'modal-see-pdf',
      dataFilterYear: [],
      dataFilteType: [],
      columns: [
        {
          label: 'TÊN ĐƠN VỊ',
          field: 'educationDepartment',
          sortable: false,
        },
        {
          label: 'TÊN BÁO CÁO',
          field: 'name',
          sortable: false,
        },
        {
          label: 'NĂM',
          field: 'year',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          sortable: false,
          width: '150px',
        },
      ],
      dataList: [],
      currentPage: 0,
      urlQuery: {
        educationDepartmentId: null,
        type: '',
        year: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      modalContent: '',
      modalCreateReport: 'comfirm-create',
      deleteIds: [],
      fileType: '',
    }
  },
  computed: {
    ...mapGetters('fiterYear', ['year', 'yearList']),
  },
  created() {
    this.fetchData(this.urlQuery)
    this.getYear()
    this.dataFilterYear = this.yearList
    this.getAllDistrict()
  },
  methods: {

    ...mapActions('fiterYear', ['getYear']),

    async getAllDistrict() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_DISTRICT)
      this.dataFilteType = data?.data
    },

    // Lấy danh sách
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_REPORT, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

    // Bộ lọc theo năm
    filterYear(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.year = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.year = ''
        this.fetchData(this.urlQuery)
      }
    },

    filterDistrict(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.educationDepartmentId = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.educationDepartmentId = ''
        this.fetchData(this.urlQuery)
      }
    },

    showPDF(id, name) {
      const paload = {
        submitReportId: id,
        reportFormat: 'PDF',
      }
      axiosApiInstance({
        url: ConstantsApi.EXPORT_REPORT, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: paload,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        this.pdfSrc = url
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Tải file có trong giấy phép lao dộng
    downloadFile(id, name) {
      const paload = {
        submitReportId: id,
      }
      axiosApiInstance({
        url: ConstantsApi.EXPORT_REPORT, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: paload,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const fileName = `${name}.pdf`
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>

<style lang="scss">
#inspectorId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }
  .win{
    .btn {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .border-status {
    border-radius: 4px;
  }
}
</style>
